import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse } from "reactstrap";

import { default as utilIcons } from '../resources/utilIcons';
import { colors } from "../styles/colors";
import SiteSelectMap from "./SiteSelectMap";

function SiteSelectMenu({ isOpen, toggle, selectedSite, setSelectedSite, siteList }) {
    const [matchingSites, setMatchingSites] = useState([]);
    const ref = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && isOpen) {
                if (!ref.current.contains(event.target) && event.target.id !== 'site-select') {
                    toggle();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [ref, isOpen, toggle]);

    useEffect(() => {
        if (inputRef.current && isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 50);
        }
    }, [inputRef, isOpen]);

    useEffect(() => {
        if (selectedSite && isOpen) {
            toggle();
        }
    }, [selectedSite]);

    return (
        <Collapse id='site-select-menu' isOpen={isOpen}>
            <div id='site-select-menu-wrapper' ref={ref}>
                <div id='site-select-menu-controls'>
                    <SiteSelectSearch
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        siteList={siteList}
                        inputRef={inputRef}
                        setMatchingSites={setMatchingSites}
                    />
                    <SiteSelectMap
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        toggle={toggle}
                        siteList={siteList}
                        matchingSites={matchingSites}
                    />
                </div>
                <Button id='site-select-menu-toggle' onClick={toggle}>{utilIcons.caret(colors['ars-neutral-400'], { rotate: '180deg' })}</Button>
            </div>
        </Collapse>
    )
}

function SiteSelectSearch({ siteList, selectedSite, setSelectedSite, inputRef, setMatchingSites }) {
    const [searchString, setSearchString] = useState('');
    const [matchingRows, setMatchingRows] = useState([]);

    useEffect(() => {
        setMatchingRows(mapSiteListToListItem());
    }, [siteList, searchString, selectedSite]);


    const handleClick = (event, siteId) => {
        setSelectedSite(String(siteId));
        setSearchString("");
    }

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.click();
        }
    }

    const mapSiteListToListItem = () => {
        const matchingList = getMatchingList();
        setMatchingSites(matchingList);
        const matchingSites = {};
        for (let site of matchingList) {
            if (site.siteId === 'all') {
                matchingSites['all'] = [site];
            } else if (site.agencyName) {
                if (!(site.agencyName in matchingSites)) {
                    matchingSites[site.agencyName] = [];
                }
                matchingSites[site.agencyName].push(site);
            } else {
                if (!('Other' in matchingSites)) {
                    matchingSites['Other'] = [];
                }
                matchingSites['Other'].push(site);
            }
        }
        const returnList = [];
        for (let agency of Object.keys(matchingSites).sort(sortAgencies)) {
            const subtable = [];
            if (agency !== 'all') {
                subtable.push(
                    <tr key={'site-select-menu-agency'} className="site-select-search-list-agency">
                        <td colSpan={2}>{agency}</td>
                    </tr>
                );
            }
            matchingSites[agency].map((site, index) => {
                const element = (
                    <tr key={'site-select-menu-site-' + index} role="button" tabIndex={0} onClick={(event) => handleClick(event, site.siteId)} onKeyDown={handleEnter} className={'site-select-search-list-item' + (String(site.siteId) === String(selectedSite) ? ' disabled' : '')}>
                        <td className="site-abbr">{site.siteAbbr}</td>
                        <td className="site-name">{site.fullName}</td>
                    </tr>
                );
                subtable.push(element);
            });
            returnList.push(
                <table key={'site-select-menu-table-' + agency}>
                    <tbody>
                        {subtable}
                    </tbody>
                </table>
            )
        }
        return returnList;
    }

    const sortAgencies = (a, b) => {
        if (a === 'all') return -1;
        else if (b === 'all') return 1;
        else {
            if (a === 'National Park Service') return -1;
            else if (b === 'National Park Service') return 1;
            else {
                if (a === 'Other') return 1;
                else if (b === 'Other') return -1;
                else return a.localeCompare(b);
            }
        }
    }

    const getMatchingList = () => {
        if (searchString === '') return siteList;
        return siteList.filter(site => checkMatch(site));
    }

    const checkMatch = (site) => {
        if (searchString === '') return true;
        const regex = new RegExp(searchString, 'i');
        return (site.siteAbbr && site.siteAbbr.match(regex) !== null) || (site.fullName && site.fullName.match(regex) !== null);
    }

    const handleChange = (event) => {
        const value = event.target.value.replace(/[^a-zA-Z0-9\-]/, '');
        setSearchString(value);
    }

    return (
        <div id='site-select-menu-search-wrapper'>
            <input id='site-select-menu-search' type='text' placeholder="Search for a site..." value={searchString} onChange={handleChange} ref={inputRef} />
            <div id='site-select-search-list'>
                {matchingRows}
            </div>
        </div>
    )
}
export default SiteSelectMenu;