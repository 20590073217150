import '../../styles/Data.css';
import StackPlots from './StackPlots';

import StripChart from "./StripChart";

function Data({ selectedSite, openFeature }) {
    const renderOpenFeature = () => {
        switch(openFeature) {
            case "stripchart": 
                return <StripChart selectedSite={selectedSite} />
            case "stackplot":
                return <StackPlots selectedSite={selectedSite} />
            default:
                return null;
        }
    }

    return <div id="data">
        {renderOpenFeature()}
    </div>;
}

export default Data;
