import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner } from "reactstrap";

import { default as bell } from "../../resources/bell-svgrepo-com.svg";

import BackButton from "../../components/BackButton";
import { SITE_URL, STATIONLOG_URL } from "../../serverConfig";
import { RestContext } from "../../App";
import { formatDate } from "../../util/timeUtils";

function StationLogIssueEditor({selectedSite, refresh, toggle}) {
    const [formData, setFormData] = useState({ title: "", detail: "", people: new Set() });
    const [siteAbbr, setSiteAbbr] = useState();
    const [people, setPeople] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [notifyMenuOpen, setNotifyMenuOpen] = useState(false);
    const [waiting, setWaiting] = useState(false);
    
    const { sendGetRequest, sendPostRequest, sendPostRequestWithOptions } = useContext(RestContext);

    const toggleNotifyMenu = () => setNotifyMenuOpen(!notifyMenuOpen);

    useEffect(() => {
        if (selectedSite !== undefined && selectedSite !== null && selectedSite !== 'all') {
            sendPostRequest(SITE_URL + '/details', { siteId: Number(selectedSite) }, (response) => {
                setSiteAbbr(response.data.aqmetAbbr);
            });
        }

        if (selectedSite !== undefined) {
            if (selectedSite === 'all') {
                sendGetRequest(STATIONLOG_URL + '/people/all', (response) => {
                    setPeople(response.data.sort((a,b) => a.fullName > b.fullName ? 1 : -1));
                    setFormData(values => ({...values, people: new Set()}));
                });
            } else {
                sendGetRequest(STATIONLOG_URL + '/people/' + selectedSite, (response) => {
                    setPeople(response.data.sort((a,b) => a.fullName > b.fullName ? 1 : -1));
                    setFormData(values => ({...values, people: new Set()}));
                });
            }
        }
    }, [selectedSite]);

    const handleSubmit = () => {
        const form = new FormData();
        form.append('title', formData['title']);
        form.append('detail', formData['detail']);
        for (let file of attachments) {
            form.append('files', file);
        }
        for (let person of formData['people']) {
            form.append('people', person);
        }
        setWaiting(true);

        sendPostRequestWithOptions(STATIONLOG_URL + '/issues/new/' + selectedSite, form, {headers: { "Content-Type": "multipart/form-data" }}, (response) => {
            setWaiting(false);
            refresh();
            toggle();
        }, (error) => {
            setWaiting(false);
        });
    }

    const canSubmit = () => {
        return selectedSite !== undefined && selectedSite !== null && selectedSite !== 'all' && formData.title !== '' && formData.detail !== '';
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }));
    }

    const getSiteAbbr = () => {
        if (selectedSite === 'all' || siteAbbr === undefined) return 'Select a site.';
        return siteAbbr;
    }

    const getDate = () => {
        const date = new Date();
        return formatDate(date);
    }

    const deleteAttachment = (file) => {
        const fileName = file.name;
        let newAttachments = [...attachments];
        for (let index in newAttachments) {
            if (newAttachments[index].name !== fileName) {
                continue;
            }
            newAttachments.splice(index, 1);
            break;
        }
        setAttachments(newAttachments);
    }

    const handlePeople = (person) => {
        const value = Number(person.userId);
        let newPeople = new Set(formData.people);
        if (newPeople.has(value)) {
            newPeople.delete(value)
        } else {
            newPeople.add(value);
        }
        setFormData(values => ({ ...values, people: newPeople }));
    }

    const personChecked = (person) => {
        if (formData === undefined) return false;
        const result = formData.people.has(person.userId);
        return result;
    }

    const mapPeopleToDropdownItem = () => {
        return people.map((person, index) =>
            <DropdownItem
                key={'people-dropdown-' + index}
                className="station-log-search-people-row"
                value={person.userId}
                onClick={() => { handlePeople(person) }}
                toggle={false}
            >
                {person.fullName}
                <Input type="checkbox" value={personChecked(person)} checked={personChecked(person)} readOnly />
            </DropdownItem>
        );
    }

    return (
        <div id='station-log-issue-editor'>
            <div id='station-log-issue-editor-header'>
                <BackButton onClick={toggle} />
                <div id='station-log-issue-editor-header-text'>
                    <Input id='station-log-issue-editor-title' type='text' name='title' value={formData['title']} placeholder='New Issue' onChange={handleChange} maxLength={100} autoFocus />
                    <div id='station-log-issue-editor-header-bottom'>
                        <div id='station-log-issue-editor-subtitles'>
                            <div id='station-log-issue-editor-abbr'>{getSiteAbbr()}</div>
                            <div id='station-log-issue-editor-date'>{getDate()}</div>
                        </div>
                        <Dropdown id="station-log-issue-editor-notify-dropdown" isOpen={notifyMenuOpen} toggle={toggleNotifyMenu}>
                            <DropdownToggle id='station-log-issue-editor-notify-button' color="primary" disabled={people.length === 0 || selectedSite === undefined || selectedSite === 'all'}><img src={bell} alt="Notification Icon" /></DropdownToggle>
                            <DropdownMenu>
                                {mapPeopleToDropdownItem()}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div id='station-log-issue-editor-detail-wrapper'>
                <Input id='station-log-issue-editor-detail' type='textarea' name='detail' value={formData['detail']} onChange={handleChange} maxLength={2000} />
                <div id='station-log-issue-editor-detail-counter'>{formData.detail.length + ' / 2000'}</div>
            </div>
            <AttachmentSelect attachments={attachments} setAttachments={setAttachments} deleteAttachment={deleteAttachment} />
            <Button className="submit-button" disabled={!canSubmit()} onClick={handleSubmit}>
                    {waiting ? <Spinner size={'sm'}/> : 'Submit'}
            </Button>
        </div>
    )
}

export function AttachmentSelect({attachments, setAttachments, deleteAttachment}) {

    const handleChange = (event) => {
        let newFiles = [...attachments];
        for (let file of event.target.files) {
            if (!newFiles.some(f => f.name === file.name) && newFiles.length < 5) {
                newFiles.push(file);
            }
        }

        setAttachments(newFiles);
    }

    const mapFilesToAttachment = () => {
        if (attachments === undefined) return null;
        return [...attachments].map((file, index) =>
            <Attachment key={'attachment-' + index} file={file} deleteAttachment={deleteAttachment} />
        )
    }

    return (
        <div id="station-log-issue-editor-attachments">
            <span>Attachments: </span>
            <div id="station-log-issue-editor-attachments-wrapper">
                {mapFilesToAttachment()}
                <label className="file-upload-button" hidden={attachments.length >= 5}>
                    <span>Select files</span>
                    <input type="file" accept=".pdf, .png, .jpg, .jpeg, .bmp, .gif, .xlsx, .heic, .heif" multiple onChange={handleChange} onClick={(event) => { event.target.value = null }} />
                </label>
            </div>
        </div>
    )
}

function Attachment({file, deleteAttachment}) {
    const handleDelete = (event) => {
        deleteAttachment(file);
    }

    return (
        <div className="file-attachment">
            <span className="file-attachment-name">{file.name}</span>
            <Button className="btn-close" onClick={handleDelete}></Button>
        </div>
    )
}

export default StationLogIssueEditor;