import { useContext, useEffect, useRef, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
    Decimation,
} from "chart.js";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import { Chart } from "react-chartjs-2";
import axios from "axios";
import { Button, Collapse, Input, Label, Spinner } from "reactstrap";

import "../../styles/StripChart.css";

import { DATA_URL, corsConfig } from "../../serverConfig";
import { checkLandscape, checkMobile } from "../../util/globals";
import { RestContext, WindowContext } from "../../App";

ChartJS.register(
    LinearScale,
    CategoryScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
    zoomPlugin,
    Decimation
);

const lineColors = [
    "#0D1011", // Black
    "#4363d8", // Blue
    "#e6194B", // Red
    "#3cb44b", // Green
    "#f58231", // Orange
    "#f032e6", // Magenta
];

const precisions = {
    "1-min": {
        interval: 60,
        text: "1-Minute",
    },
    "5-min": { interval: 5 * 60, text: "5-Minute" },
    "15-min": { interval: 15 * 60, text: "15-Minute" },
    hourly: { interval: 60 * 60, text: "Hourly" },
    daily: { interval: 24 * 60 * 60, text: "Daily" },
    "24-hr run": { interval: 60 * 60, text: "24 Hour Run" },
    cumulative: { interval: 60 * 60, text: "Cumulative" },
};

function StripChart({ selectedSite }) {
    const [columns, setColumns] = useState([]);
    const [units, setUnits] = useState([]);
    const [data, setData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({
        selectedSite: selectedSite,
        precision: "hourly",
        selectedSpan: 7,
        sameSiteAndPrecision: false,
    });
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [channels, setChannels] = useState({});
    const [dateRange, setDateRange] = useState({});
    const [yRange, setYRange] = useState({});
    const [y1Range, setY1Range] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(1);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const { isMobile } = useContext(WindowContext);
    const { sendPostRequest } = useContext(RestContext);

    const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

    const chartRef = useRef(null);
    const wrapperRef = useRef(null);
    const loadingOptions = useRef(loading);

    useEffect(() => {
        setSelectedOptions((values) => ({
            ...values,
            selectedSite: selectedSite,
        }));
    }, [selectedSite]);

    useEffect(() => {
        beginLoad();
    }, [selectedOptions]);

    useEffect(() => {
        if (loading) {
            loadInfo();
        } else {
            setLoadingProgress(0);
        }
    }, [loading]);

    useEffect(() => {
        if (columns && columns.length > 0 && data) {
            let newChannels = {};
            for (let i = 0; i < columns.length; i += 2) {
                newChannels[columns[i]] = null;
            }
            setChannels(newChannels);
        }
    }, [columns]);

    useEffect(() => {
        setDateRange({ min: getMinDate(), max: getMaxDate() });
        setYRange({ min: getYDefaultMin("y"), max: getYDefaultMax("y") });
        setY1Range({ min: getYDefaultMin("y1"), max: getYDefaultMax("y1") });
    }, [chartData]);

    useEffect(() => {
        doZoom();
    }, [dateRange, yRange, y1Range]);

    useEffect(() => {
        if (channels && Object.keys(channels).length && !loading) {
            generateDataObject(columns, units, data);
        }
    }, [channels, loading]);

    const beginLoad = () => {
        if (!selectedOptions) return;
        const dataOptions = {
            selectedSite: selectedOptions.selectedSite,
            precision: selectedOptions.precision,
            selectedSpan: selectedOptions.selectedSpan,
            sameSiteAndPrecision: selectedOptions.sameSiteAndPrecision,
            loadedMin: selectedOptions.sameSiteAndPrecision
                ? getLoadedMin()
                : null,
            loadedMax: selectedOptions.sameSiteAndPrecision
                ? getLoadedMax()
                : null,
        };
        if (!loading) {
            setLoading(dataOptions);
        }
    };

    const loadInfo = () => {
        if (!selectedOptions) return;
        console.log(1);
        loadingOptions.current = loading;
        if (loading) {
            console.log(2);
            if (
                loading.loadedMin &&
                loading.loadedMin <
                    moment()
                        .subtract(selectedOptions.selectedSpan, "day")
                        .startOf("day")
                        .unix()
            ) {
                setLoading(null);
                return;
            }
            const payload = {
                precision: loading.precision,
                span: loading.selectedSpan,
                loadedMin: loading.loadedMin,
                loadedMax: loading.loadedMax,
            };
            console.log(3);
            sendPostRequest(
                `${DATA_URL}/stripcharts/info/${loading.selectedSite}`,
                payload,
                (response) => {
                    console.log(4);
                    const numPages = response.data.numPages;
                    const pageSize = response.data.pageSize;
                    setLoadingProgress(0);
                    if (numPages > 0) {
                        setColumns(response.data.columns);
                        setUnits(response.data.units);
                        if (loading.sameSiteAndPrecision) {
                            loadPage(1, numPages, pageSize, loading, data);
                        } else {
                            loadPage(1, numPages, pageSize, loading);
                        }
                    } else {
                        if (!loading.sameSiteAndPrecision) {
                            setColumns([]);
                            setUnits([]);
                            setData({});
                        }
                        setLoading(null);
                    }
                },
                (error) => {
                    setLoading(null);
                }
            );
        }
    };

    const loadPage = (
        pageNumber,
        numPages,
        pageSize,
        dataOptions,
        tempData = {}
    ) => {
        if (
            JSON.stringify(loadingOptions.current) ===
            JSON.stringify(dataOptions)
        ) {
            const payload = {
                precision: dataOptions.precision,
                span: dataOptions.selectedSpan,
                page: pageNumber,
                pageSize: pageSize,
                numPages: numPages,
                loadedMin: dataOptions.loadedMin,
                loadedMax: dataOptions.loadedMax,
            };
            axios
                .post(
                    `${DATA_URL}/stripcharts/${selectedSite}`,
                    payload,
                    corsConfig
                )
                .then((response) => {
                    if (
                        JSON.stringify(loadingOptions.current) ===
                        JSON.stringify(dataOptions)
                    ) {
                        const responseData = response.data;

                        tempData = { ...tempData, ...responseData.data };
                        setData(tempData);
                        setLoadingProgress(pageNumber / numPages);
                        if (pageNumber < numPages) {
                            loadPage(
                                pageNumber + 1,
                                numPages,
                                pageSize,
                                dataOptions,
                                tempData
                            );
                        } else {
                            setLoading(null);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(null);
                });
        }
    };

    const getLoadedMin = () => {
        if (!data) return null;
        const timestamps = Object.keys(data);
        if (!timestamps.length) return null;
        return getMin(timestamps);
    };

    const getLoadedMax = () => {
        if (!data) return null;
        const timestamps = Object.keys(data);
        if (!timestamps.length) return null;
        return getMax(timestamps);
    };

    const getTimespanOptions = () => {
        if (!selectedOptions || !selectedOptions.precision) return null;
        const hourlyOptions = [1, 3, 5, 7, 14, 21, 30, 60];
        const minuteOptions = [1, 2, 3, 4, 5, 6, 7, 14, 21, 30, 60];
        const options = selectedOptions.precision.match(/\d+-min/)
            ? minuteOptions
            : hourlyOptions;
        return options.map((option) => <option>{option}</option>);
    };

    const handlePrecisionSelect = (event) => {
        const value = event.target.value;
        const newOptions = {
            precision: value,
            selectedSpan: value === "hourly" ? 7 : 3,
            sameSiteAndPrecision: false,
            selectedSite: selectedSite,
        };
        setSelectedOptions(newOptions);
    };

    const handleTimespanSelect = (event) => {
        const value = Number(event.target.value);
        setSelectedOptions((values) => ({
            ...values,
            selectedSpan: value,
            sameSiteAndPrecision: true,
        }));
    };

    const buildChartOptions = () => {
        const zoomLimits = selectedOptions.precision.match(/\d+-min/)
            ? {
                  x: {
                      min: "original",
                      max: "original",
                      minRange: 60 * 60 * 1000,
                  },
              }
            : {
                  x: {
                      min: "original",
                      max: "original",
                      minRange: 12 * 60 * 60 * 1000,
                  },
              };

        let options = {
            responsive: true,
            animation: false,
            interaction: {
                mode: "index",
                intersect: false,
            },
            datasets: {
                line: {
                    spanGaps: false,
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        generateLabels: (chart) => {
                            if (!chart || !chart.data || !chart.data.datasets) {
                                return [];
                            }
                            return chart.data.datasets.map((dataset, i) => {
                                let index = columns.indexOf(dataset.label);
                                let legendItem = {
                                    text: dataset.label,
                                    fillStyle: "transparent",
                                    strokeStyle: dataset.borderColor,
                                    lineWidth: dataset.borderWidth,
                                    lineDash: dataset.borderDash,
                                    fontColor: "black",
                                    hidden: false,
                                };
                                if (index === -1) {
                                    return legendItem;
                                } else {
                                    legendItem.text = `${dataset.label} (${units[index]})`;
                                    return legendItem;
                                }
                            });
                        },
                    },
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: "x",
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true,
                        },
                        mode: "x",
                    },
                    limits: zoomLimits,
                },
                decimation: {
                    enabled: true,
                },
                tooltip: {
                    position: "nearest",
                },
            },
            scales: {
                x: {
                    type: "time",
                    ticks: {
                        maxRotation: 80,
                        minRotation: 80,
                        major: {
                            enabled: true,
                        },
                    },
                },
                y: {
                    position: "left",
                    type: "linear",
                    suggestedMin: 0,
                    suggestedMax: 1,
                },
                y1: {
                    display: false,
                    position: "right",
                    type: "linear",
                    suggestedMin: 0,
                    suggestedMax: 1,
                    grid: {
                        drawOnChartArea: false,
                    },
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
            maintainAspectRatio: false,
            parsing: false,
        };

        return options;
    };

    const resetXZoom = () => {
        setDateRange({ min: getMinDate(), max: getMaxDate() });
    };

    const doZoom = () => {
        const yAxes = { y: yRange, y1: y1Range };
        if (chartRef.current && chartData.labels.length > 0) {
            const chart = chartRef.current;
            let rangeToZoom = {};
            for (let axisLabel in yAxes) {
                let range = yAxes[axisLabel];
                if (range.min === undefined && range.max === undefined) {
                    rangeToZoom = {
                        min: Math.max(-10, getYDefaultMin(axisLabel)),
                        max: getYDefaultMax(axisLabel),
                    };
                } else {
                    rangeToZoom = {
                        min:
                            range.min === undefined
                                ? getYDefaultMin(axisLabel)
                                : range.min,
                        max:
                            range.max === undefined
                                ? getYDefaultMax(axisLabel)
                                : range.max,
                    };
                }
                if (rangeToZoom.min === rangeToZoom.max) rangeToZoom.max += 0.1;
                chart.options.scales[axisLabel].min = rangeToZoom.min;
                chart.options.scales[axisLabel].max = rangeToZoom.max;
            }
            if (dateRange.min || dateRange.max) {
                rangeToZoom = {
                    min: dateRange.min
                        ? moment(dateRange.min, "YYYY-MM-DDTHH:mm").valueOf()
                        : chartData.labels[0],
                    max: dateRange.max
                        ? moment(dateRange.max, "YYYY-MM-DDTHH:mm").valueOf()
                        : chartData.labels[chartData.labels.length - 1],
                };
            } else {
                rangeToZoom = {
                    min: chartData.labels[0],
                    max: chartData.labels[chartData.labels.length - 1],
                };
            }
            chart.options.scales.x.min = rangeToZoom.min;
            chart.options.scales.x.max = rangeToZoom.max;
            chart.update();
        }
    };

    const resetYZoom = () => {
        setYRange({ min: getYDefaultMin("y"), max: getYDefaultMax("y") });
        setY1Range({ min: getYDefaultMin("y1"), max: getYDefaultMax("y1") });
    };

    const getMinDate = () => {
        if (!chartData || !chartData.labels) return 0;
        return moment(chartData.labels[0]).format("YYYY-MM-DDTHH:mm");
    };

    const getMaxDate = () => {
        if (!chartData || !chartData.labels) return 0;
        return moment(chartData.labels[chartData.labels.length - 1]).format(
            "YYYY-MM-DDTHH:mm"
        );
    };

    const getYMin = () => {
        if (yRange.min === undefined) return "";
        return yRange.min;
    };

    const getYMax = () => {
        if (yRange.max === undefined) return "";
        return yRange.max;
    };

    const getY1Min = () => {
        if (y1Range.min === undefined) return "";
        return y1Range.min;
    };

    const getY1Max = () => {
        if (y1Range.max === undefined) return "";
        return y1Range.max;
    };

    const getYDefaultMin = (axisLabel) => {
        if (chartData.datasets.length === 0) return 0;
        const axisChannels =
            axisLabel === "y" ? getLeftChannels() : getRightChannels();
        const min = getMin(
            chartData.datasets
                .filter((dataset) => axisChannels.includes(dataset.label))
                .map((dataset) => dataset.min)
        );
        return min === -Infinity
            ? 0
            : min > 0
            ? Math.floor(min * 0.9 * 100) / 100
            : Math.floor(min * 1.1 * 100) / 100;
    };

    const getYDefaultMax = (axisLabel) => {
        if (chartData.datasets.length === 0) return 1;
        const axisChannels =
            axisLabel === "y" ? getLeftChannels() : getRightChannels();
        const max = getMax(
            chartData.datasets
                .filter((dataset) => axisChannels.includes(dataset.label))
                .map((dataset) => dataset.max)
        );
        return max === Infinity
            ? 1
            : max < 0
            ? Math.ceil(max * 0.9 * 100) / 100
            : Math.ceil(max * 1.1 * 100) / 100;
    };

    const handleMinDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, min: value }));
    };

    const handleMaxDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, max: value }));
    };

    const handleYMin = (event) => {
        const value = event.target.value;
        setYRange((values) => ({
            ...values,
            min:
                value === ""
                    ? undefined
                    : Math.round(Number(value) * 100) / 100,
        }));
    };

    const handleYMax = (event) => {
        const value = event.target.value;
        setYRange((values) => ({
            ...values,
            max:
                value === ""
                    ? undefined
                    : Math.round(Number(value) * 100) / 100,
        }));
    };

    const handleY1Min = (event) => {
        const value = event.target.value;
        setY1Range((values) => ({
            ...values,
            min:
                value === ""
                    ? undefined
                    : Math.round(Number(value) * 100) / 100,
        }));
    };

    const handleY1Max = (event) => {
        const value = event.target.value;
        setY1Range((values) => ({
            ...values,
            max:
                value === ""
                    ? undefined
                    : Math.round(Number(value) * 100) / 100,
        }));
    };

    const getInterval = (precision) => {
        if (!precision) return 3600;
        return precisions[precision].interval;
    };

    const generateDataObject = (columns, units, data) => {
        if (!selectedOptions) return;
        let timestamps = Object.keys(data).sort();
        const minOfSpan = moment()
            .subtract(selectedOptions.selectedSpan, "day")
            .startOf("day")
            .unix();
        let dataLoadedMin = Math.max(minOfSpan, Number(timestamps[0]));
        let dataLoadedMax = Math.min(
            moment().unix(),
            Number(timestamps[timestamps.length - 1])
        );
        timestamps = [];
        let interval = getInterval(selectedOptions.precision);
        for (
            let i = 0;
            i <= (dataLoadedMax - dataLoadedMin) / interval;
            i++
        ) {
            timestamps.push(dataLoadedMin + i * interval);
        }
        let labels = timestamps.map((timestamp) => timestamp * 1000);

        const dataObj = {
            labels,
            datasets: [],
        };

        for (let i = 0; i < columns.length / 2; i++) {
            const channelName = columns[i * 2];
            if (channels[channelName]) {
                let lineData = timestamps.map((timestamp) => ({
                    x: timestamp * 1000,
                    y: data[timestamp] ? data[timestamp][i * 2] : null,
                }));
                let lineFlags = timestamps.map((timestamp) =>
                    data[timestamp] ? data[timestamp][i * 2 + 1] : null
                );
                const line = {
                    type: "line",
                    label: channelName,
                    borderColor: lineColors[i % lineColors.length],
                    borderWidth: 2,
                    borderDash:
                        i >= lineColors.length
                            ? [6 * Math.floor(i / lineColors.length), 6, 6, 6]
                            : [],
                    backgroundColor: "transparent",
                    fill: false,
                    data: lineData,
                    xAxisId: "x",
                    yAxisID: getLeftChannels().includes(channelName)
                        ? "y"
                        : "y1",
                    tension: 0,
                    min: getMin(
                        lineData.map((point) => (point ? point.y : null))
                    ),
                    max: getMax(
                        lineData.map((point) => (point ? point.y : null))
                    ),

                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                return `${channelName}: ${
                                    tooltipItem.formattedValue
                                } ${units[i * 2]}`;
                            },
                            afterLabel: (tooltipItem) => {
                                return lineFlags[tooltipItem.dataIndex]
                                    ? `${columns[i * 2 + 1]}: ${
                                          lineFlags[tooltipItem.dataIndex]
                                      }`
                                    : null;
                            },
                        },
                    },
                };

                dataObj.datasets.push(line);
            }
        }

        setChartData(dataObj);
    };

    const getMin = (data) => {
        if (data.length === 0) return -Infinity;
        else if (data.length === 1) return data[0];
        else {
            let min = data[0];
            for (let i = 1; i < data.length; i++) {
                if (data[i] < min) min = data[i];
            }
            return min;
        }
    };

    const getMax = (data) => {
        if (data.length === 0) return Infinity;
        else if (data.length === 1) return data[0];
        else {
            let max = data[0];
            for (let i = 1; i < data.length; i++) {
                if (data[i] > max) max = data[i];
            }
            return max;
        }
    };

    const getLeftChannels = () => {
        if (!channels) return [];
        return Object.keys(channels).filter(
            (channel) => channels[channel] === "left"
        );
    };

    const getRightChannels = () => {
        if (!channels) return [];
        return Object.keys(channels).filter(
            (channel) => channels[channel] === "right"
        );
    };

    const toggleChannel = (value, side) => {
        setChannels((values) => ({
            ...values,
            [value]: channels[value] ? null : side,
        }));
    };

    const selectAllAvailableChannelsForSide = (side) => {
        let newChannels = { ...channels };
        switch (side) {
            case "left":
                const rightChannels = getRightChannels();
                for (let channel of Object.keys(channels)) {
                    if (!rightChannels.includes(channel)) {
                        newChannels[channel] = "left";
                    }
                }
                setChannels(newChannels);
                break;
            case "right":
                const leftChannels = getLeftChannels();
                for (let channel of Object.keys(channels)) {
                    if (!leftChannels.includes(channel)) {
                        newChannels[channel] = "right";
                    }
                }
                setChannels(newChannels);
                break;
            default:
                break;
        }
    };

    const clearAllChannelsForSide = (side) => {
        let newChannels = { ...channels };
        switch (side) {
            case "left":
                for (let channel of getLeftChannels()) {
                    newChannels[channel] = null;
                }
                setChannels(newChannels);
                break;
            case "right":
                for (let channel of getRightChannels()) {
                    newChannels[channel] = null;
                }
                setChannels(newChannels);
                break;
            default:
                break;
        }
    };

    const doXScroll = (scrollOptions) => {
        if (
            !dateRange ||
            dateRange.min === undefined ||
            dateRange.max === undefined ||
            !chartData ||
            !chartData.labels ||
            chartData.labels.length < 2
        )
            return;
        const { direction, quantity, unit } = scrollOptions;
        let offset = 0;
        switch (direction) {
            case "left":
                let dataMin = moment(chartData.labels[0]);
                let currentMin = moment(dateRange.min, "YYYY-MM-DDTHH:mm");
                let targetMin = moment(currentMin).subtract(quantity, unit);
                if (targetMin.unix() < dataMin.unix()) {
                    offset = currentMin.unix() - dataMin.unix();
                } else {
                    offset = currentMin.unix() - targetMin.unix();
                }
                setDateRange({
                    min: currentMin
                        .subtract(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                    max: moment(dateRange.max, "YYYY-MM-DDTHH:mm")
                        .subtract(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                });
                return;
            case "right":
                let dataMax = moment(
                    chartData.labels[chartData.labels.length - 1]
                );
                let currentMax = moment(dateRange.max, "YYYY-MM-DDTHH:mm");
                let targetMax = moment(currentMax).add(quantity, unit);
                if (targetMax.unix() > dataMax.unix()) {
                    offset = dataMax.unix() - currentMax.unix();
                } else {
                    offset = targetMax.unix() - currentMax.unix();
                }
                setDateRange({
                    min: moment(dateRange.min, "YYYY-MM-DDTHH:mm")
                        .add(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                    max: currentMax
                        .add(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                });
                return;
            default:
                return;
        }
    };

    const finalizeOptions = () => {
        const finalOptions = buildChartOptions();
        finalOptions.scales.y1.display = getRightChannels().length > 0;
        return finalOptions;
    };

    const noData = () => {
        const noDataOrDataLengthZero = !data || Object.keys(data).length === 0;
        return noDataOrDataLengthZero;
    };

    const mapPrecisions = () => {
        return Object.keys(precisions).map((precision) => (
            <option value={precision}>{precisions[precision].text}</option>
        ));
    };

    const getDataControls = () => {
        return (
            <div className="data-controls">
                <div className="precision-select">
                    <label>Precision:</label>
                    <select
                        value={selectedOptions.precision}
                        onChange={handlePrecisionSelect}
                    >
                        {mapPrecisions()}
                    </select>
                </div>

                <div className="timespan-select">
                    <label>Timespan: </label>
                    <select
                        onChange={handleTimespanSelect}
                        value={selectedOptions.selectedSpan}
                    >
                        {getTimespanOptions()}
                    </select>
                    <span>Days</span>
                </div>

                <div className="loading-wrapper" hidden={isMobile || !loading}>
                    <Label>Loading</Label>
                    <progress value={loadingProgress} />
                    <span>{Math.round(loadingProgress * 1000) / 10}%</span>
                </div>
            </div>
        );
    };

    const getChartControls = () => {
        return (
            <div className="chart-controls">
                <Label>Parameters (Left)</Label>
                <ParameterSelect
                    channels={channels}
                    toggleChannel={toggleChannel}
                    side={"left"}
                    leftChannels={getLeftChannels()}
                    rightChannels={getRightChannels()}
                    selectAll={selectAllAvailableChannelsForSide}
                    clearAll={clearAllChannelsForSide}
                />
                <Label>Parameters (Right)</Label>
                <ParameterSelect
                    channels={channels}
                    toggleChannel={toggleChannel}
                    side={"right"}
                    leftChannels={getLeftChannels()}
                    rightChannels={getRightChannels()}
                    selectAll={selectAllAvailableChannelsForSide}
                    clearAll={clearAllChannelsForSide}
                />
                <Label>Start Time</Label>
                <input
                    type="datetime-local"
                    onChange={handleMinDate}
                    min={getMinDate()}
                    max={dateRange.max ? dateRange.max : getMaxDate()}
                    step={
                        selectedOptions
                            ? selectedOptions.precision.match(/\d+-min/)
                                ? 60
                                : 3600
                            : 1
                    }
                    value={dateRange.min || ""}
                />
                <Label>End Time</Label>
                <input
                    type="datetime-local"
                    onChange={handleMaxDate}
                    min={dateRange.min ? dateRange.min : getMinDate()}
                    max={getMaxDate()}
                    step={
                        selectedOptions
                            ? selectedOptions.precision.match(/\d+-min/)
                                ? 60
                                : 3600
                            : 1
                    }
                    value={dateRange.max || ""}
                />
                <DateScroller doXScroll={doXScroll} />
                <Button onClick={resetXZoom}>Reset Time Range</Button>
                <Label>Y Axis (Left)</Label>
                <div className="y-inputs-wrapper">
                    <Input
                        type="number"
                        onChange={handleYMin}
                        max={yRange.max}
                        value={getYMin()}
                    />
                    <span>-</span>
                    <Input
                        type="number"
                        onChange={handleYMax}
                        min={yRange.min}
                        value={getYMax()}
                    />
                </div>
                <Label>Y Axis (Right)</Label>
                <div className="y-inputs-wrapper">
                    <Input
                        type="number"
                        onChange={handleY1Min}
                        max={y1Range.max}
                        value={getY1Min()}
                    />
                    <span>-</span>
                    <Input
                        type="number"
                        onChange={handleY1Max}
                        min={y1Range.min}
                        value={getY1Max()}
                    />
                </div>
                <Button onClick={resetYZoom}>Reset Y Axis</Button>
            </div>
        );
    };

    const desktopStripChart = () => {
        return (
            <div className="stripchart">
                <NoData hidden={loading || !noData()} />
                <div className="stripchart-header">{getDataControls()}</div>
                <div className="stripchart-main">
                    <LoadingScreen hidden={!loading} />
                    {getChartControls()}
                    <div className="stripchart-wrapper" ref={wrapperRef}>
                        <Chart
                            type="line"
                            data={chartData}
                            options={finalizeOptions()}
                            ref={chartRef}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const mobileMenu = () => {
        return (
            <div className="stripchart-mobile-menu">
                <Button
                    className="stripchart-mobile-menu-toggle"
                    onClick={toggleMobileMenu}
                >
                    Configure Chart
                </Button>
                <Collapse
                    className="stripchart-mobile-menu-collapse"
                    isOpen={mobileMenuOpen}
                    toggle={toggleMobileMenu}
                >
                    <div className="stripchart-mobile-menu-collapse-wrapper">
                        {getDataControls()}
                        {getChartControls()}
                    </div>
                </Collapse>
            </div>
        );
    };

    const mobileStripChart = () => {
        return (
            <div className="stripchart">
                <NoData hidden={loading || !noData()} />
                <div className="stripchart-header">{mobileMenu()}</div>
                <div className="stripchart-main">
                    <LoadingScreen
                        hidden={!loading}
                        loadingProgress={loadingProgress}
                        isMobile
                    />
                    <div className="stripchart-wrapper" ref={wrapperRef}>
                        <Chart
                            type="line"
                            data={chartData}
                            options={finalizeOptions()}
                            ref={chartRef}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return isMobile ? mobileStripChart() : desktopStripChart();
}

function ParameterSelect({
    channels,
    toggleChannel,
    side,
    leftChannels,
    rightChannels,
    selectAll,
    clearAll,
}) {
    const sideHasChannel = (channel) => {
        switch (side) {
            case "left":
                return leftChannels.includes(channel);
            case "right":
                return rightChannels.includes(channel);
            default:
                return false;
        }
    };

    const mapChannels = () => {
        if (!channels) return null;
        return Object.keys(channels).map((channel, index) => (
            <div
                key={"stripchart-parameter-select-channel-" + index}
                className={
                    "stripchart-parameter-select-channel" +
                    (isSelected(channel)
                        ? sideHasChannel(channel)
                            ? " selected"
                            : " disabled"
                        : "")
                }
                onClick={handleClick}
                value={channel}
            >
                {channel}
            </div>
        ));
    };

    const handleClick = (event) => {
        const value = event.target.getAttribute("value");
        toggleChannel(value, side);
    };

    const isSelected = (channel) => {
        return channels[channel];
    };

    return (
        <div className="stripchart-parameter-select">
            <div className="stripchart-parameter-container">
                {mapChannels()}
            </div>
            <div className="stripchart-parameter-buttons-wrapper">
                <Button onClick={() => selectAll(side)}>Select All</Button>
                <Button onClick={() => clearAll(side)}>Clear</Button>
            </div>
        </div>
    );
}

function DateScroller({ doXScroll }) {
    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState("days");

    const handleQuantity = (event) => {
        const value = Number(event.target.value);
        setQuantity(value);
    };

    const handleUnit = (event) => {
        const value = event.target.value;
        setUnit(value);
    };

    const handleMinus = () => {
        doXScroll({
            direction: "left",
            quantity,
            unit,
        });
    };

    const handlePlus = () => {
        doXScroll({
            direction: "right",
            quantity,
            unit,
        });
    };

    return (
        <div className="date-scroller">
            <Button onClick={handleMinus}>-</Button>
            <input
                className="time-quantity-input"
                type="number"
                step={1}
                value={quantity}
                onChange={handleQuantity}
            />
            <select
                className="time-unit-select"
                value={unit}
                onChange={handleUnit}
            >
                <option value="hours">Hour(s)</option>
                <option value="days">Day(s)</option>
                <option value="weeks">Week(s)</option>
                <option value="months">Month(s)</option>
            </select>
            <Button onClick={handlePlus}>+</Button>
        </div>
    );
}

function LoadingScreen({ hidden, loadingProgress = 0, isMobile = false }) {
    const getContent = () => {
        if (isMobile) {
            return (
                <div className="loading-wrapper">
                    <progress value={loadingProgress} />
                    <span>{Math.round(loadingProgress * 1000) / 10}%</span>
                </div>
            );
        }
    };

    return (
        <div
            className={"loading" + (isMobile ? " mobile" : "")}
            hidden={hidden}
        >
            {getContent()}
        </div>
    );
}

function NoData({ hidden }) {
    return (
        <div className="no-data" hidden={hidden}>
            No data found for selected settings. Please select a different site,
            or try a different precision/timespan.
        </div>
    );
}

export default StripChart;
