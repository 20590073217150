import moment from "moment";

const timezones = {
    "Pacific/Honolulu": {
        STD: "HST",
        DST: "HST",
    },
    "America/Anchorage": {
        STD: "AKST",
        DST: "AKDT",
    },
    "America/Los_Angeles": {
        STD: "PST",
        DST: "PDT",
    },
    "America/Boise": {
        STD: "MST",
        DST: "MDT",
    },
    "America/Denver": {
        STD: "MST",
        DST: "MDT",
    },
    "America/Chicago": {
        STD: "CST",
        DST: "CDT",
    },
    "America/New_York": {
        STD: "EST",
        DST: "EDT",
    },
    "Etc/GMT+10": {
        STD: "HST",
        DST: "HST",
    },
    "Etc/GMT+9": {
        STD: "AKST",
        DST: "AKDT",
    },
    "Etc/GMT+8": {
        STD: "PST",
        DST: "PDT",
    },
    "Etc/GMT+7": {
        STD: "MST",
        DST: "MDT",
    },
    "Etc/GMT+6": {
        STD: "CST",
        DST: "CDT",
    },
    "Etc/GMT+5": {
        STD: "EST",
        DST: "EDT",
    },
    "America/Phoenix": {
        STD: "MST",
        DST: "MST",
    },
};

export function getTimezoneName(timezone) {
    return timezone.split("/")[1].replace("_", " ");
}

export function getTimezoneAbbr(timezone, standard) {
    if (standard === undefined) {
        standard = moment.tz(new Date(), timezone).isDST();
    }

    const tz = timezones[timezone];
    if (standard) {
        return tz ? tz["DST"] : "DST";
    } else {
        return tz ? tz["STD"] : "STD";
    }
}

export function today() {
    return new Date().toISOString().split("T")[0];
}

export function last30() {
    return new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .split("T")[0];
}

export function last90() {
    return new Date(new Date().setDate(new Date().getDate() - 90))
        .toISOString()
        .split("T")[0];
}

export function formatDate(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    return `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}

export function formatDatetime(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    return `${dateArray[3]}:${dateArray[4]} ${dateArray[6]}, ${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}

export function formatDatetimeWithLocalTimezone(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    const localTZ = Intl.DateTimeFormat().resolvedOptions();
    return `${dateArray[3]}:${dateArray[4]} ${dateArray[6]} ${getTimezoneAbbr(
        localTZ.timeZone
    )}, ${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}
