import React, { useEffect, useRef, useState } from "react";
import { Collapse, Input } from "reactstrap";

function SearchableSelect({
    data,
    selected,
    setSelected,
    valField,
    textField,
    groupField = null,
    sortFn,
    groupSortFn,
    className,
    name,
    onChange,
    disabled,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [maxWidth, setMaxWidth] = useState(0);
    const ref = useRef(null);
    const collapseRef = useRef(null);
    const internalRef = useRef(null);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (data && textField) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            context.font = "16px roboto";
            let newMax = 0;
            for (let item of data) {
                const width = context.measureText(item[textField]).width;
                if (width > newMax) {
                    newMax = Math.ceil(width);
                }
            }
            setMaxWidth(newMax + 25);
        }
    }, [data, textField]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && isOpen) {
                if (
                    !ref.current.contains(event.target) &&
                    event.target.id !== "site-select"
                ) {
                    setSearchString("");
                    toggle();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("focusin", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleClickOutside);
        };
    }, [ref, isOpen, toggle]);

    const getMatchingList = () => {
        let matchingData;
        if (searchString === "") {
            matchingData = data;
        } else {
            matchingData = data.filter((item) => checkMatch(item));
        }
        if (sortFn) {
            return matchingData.sort(sortFn);
        } else {
            return matchingData.sort((a, b) =>
                a[textField].localeCompare(b[textField])
            );
        }
    };

    const checkMatch = (item) => {
        if (searchString === "") return true;
        const regex = new RegExp(searchString, "i");
        return (
            (item[textField] && item[textField].match(regex) !== null) ||
            (groupField
                ? item[groupField] && item[groupField].match(regex) !== null
                : false)
        );
    };

    const sortGroups = (a, b) => {
        if (groupSortFn) {
            return groupSortFn(a, b);
        } else {
            if (a === "null") return -1;
            else if (b === "null") return 1;
            else return a.localeCompare(b);
        }
    };

    const mapData = () => {
        if (!data || !valField || !textField) return null;
        const matchingList = getMatchingList();
        if (groupField) {
            let groups = {};
            matchingList.map((item, index) => {
                let group = item[groupField];
                if (!(group in groups)) {
                    groups[group] = [];
                }
                groups[group].push(
                    <div
                        className={
                            "searchable-select-option" +
                            (selected && selected[valField] === item[valField]
                                ? " selected"
                                : "")
                        }
                        value={item[valField]}
                        onClick={(event) => handleClick(event, item)}
                    >
                        {item[textField]}
                    </div>
                );
            });
            let rows = [];
            for (let group of Object.keys(groups).sort(sortGroups)) {
                if (group === "null") {
                    rows = [...rows, ...groups[group]];
                } else {
                    rows = [
                        ...rows,
                        <div className="searchable-select-group-name">
                            {group}
                        </div>,
                        ...groups[group],
                    ];
                }
            }
            return rows;
        } else {
            return matchingList.map((item, index) => (
                <div
                    key={"option-" + index}
                    className={
                        "searchable-select-option" +
                        (selected && selected[valField] === item[valField]
                            ? " selected"
                            : "")
                    }
                    value={item[valField]}
                    onMouseDown={(event) => handleClick(event, item)}
                >
                    {item[textField]}
                </div>
            ));
        }
    };

    const handleClick = (event, item) => {
        setSelected(item[valField]);
        setSearchString("");
        setIsOpen(false);
    };

    const handleChange = (event) => {
        const value = event.target.value.replace(/[^a-zA-Z0-9\-]/, '');
        setSearchString(value);
    };

    const getSelectedText = () => {
        if (!selected || !data) return "";
        const foundItem = data.find((item) => item[valField] === selected);
        return foundItem ? foundItem[textField] : "";
    };

    const getCollapseStyle = () => {
        let style = { minWidth: maxWidth + "px" };
        return style;
    };

    return (
        <div
            className={"searchable-select" + (className ? " " + className : "") + (disabled ? " disabled" : "")}
            ref={ref}
            name={name}
            {...props}
        >
            <div className="searchable-select-selected" hidden={isOpen}>
                {getSelectedText()}
            </div>
            <Input
                value={searchString}
                onChange={handleChange}
                onFocus={() => setIsOpen(true)}
                className="searchable-select-input"
                style={{ cursor: !isOpen ? "pointer" : null }}
            />
            <div className="searchable-select-collapse-wrapper">
                <div
                    className="searchable-select-collapse"
                    hidden={!isOpen}
                    style={getCollapseStyle()}
                    ref={collapseRef}
                >
                    <div className="searchable-select-collapse-content">
                        <div className="searchable-select-options-wrapper">
                            {mapData()}
                        </div>
                    </div>
                </div>
            </div>
            <input ref={internalRef} onInput={onChange} hidden />
        </div>
    );
}

export default SearchableSelect;
