import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
    Decimation,
} from "chart.js";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import { Chart } from "react-chartjs-2";

ChartJS.register(
    LinearScale,
    CategoryScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
    zoomPlugin,
    Decimation
);

const Y_AXIS_LABEL_WIDTH = 50;

const defaultPlotOptions = {
    responsive: true,
    animation: false,
    interaction: {
        mode: "index",
        intersect: false,
    },
    layout: {
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: Y_AXIS_LABEL_WIDTH,
        },
    },
    datasets: {
        line: {
            spanGaps: false,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            position: "nearest",
        },
        decimation: {
            enabled: true,
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true,
                },
                mode: "xy",
            },
            pan: {
                enabled: true,
                mode: "xy",
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                    minRange: 60 * 60 * 1000,
                },
            },
        },
    },
    scales: {
        x: {
            type: "time",
            time: {
                minUnit: "hour",
            },
            ticks: {
                display: false,
            },
        },
        y: {
            type: "linear",
            suggestedMin: 0,
            suggestedMax: 1,
        },
    },
    elements: {
        point: {
            radius: 0,
        },
    },
    events: [
        "mousemove",
        "mouseout",
        "click",
        "touchstart",
        "touchmove",
        "dblclick",
    ],
    maintainAspectRatio: false,
    parsing: false,
};

const xAxisOptions = {
    responsive: true,
    animation: false,
    layout: {
        padding: {
            top: 0,
            bottom: 0,
            left: Y_AXIS_LABEL_WIDTH,
            right: Y_AXIS_LABEL_WIDTH,
        },
    },
    scales: {
        x: {
            type: "time",
            position: "top",
            time: {
                unit: "hour",
                displayFormats: {
                    minute: "hh:mm a",
                    hour: "hh:mm a",
                    day: "MM/DD/YYYY",
                },
            },
            ticks: {
                major: {
                    enabled: true,
                },
            },
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
        },
        y: {
            display: false,
        },
    },
    plugins: {
        zoom: {
            zoom: {
                mode: "x",
            },
            pan: {
                enabled: true,
                mode: "x",
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                    minRange: 60 * 60 * 1000,
                },
            },
        },
    },
    maintainAspectRatio: false,
};

const StackPlotContext = createContext(null);

function StackPlot({ columns, data, config, dateRange = null }) {
    const [charts, setCharts] = useState([]);
    const [chartRefs, setChartRefs] = useState(new Map());

    useEffect(() => {
        if (columns && data && config) {
            mapPlots();
        } else {
            setCharts([]);
        }
    }, [columns, data, config]);

    useEffect(() => {
        if (dateRange?.min && dateRange?.max) {
            zoomAll(
                moment(dateRange.min, "YYYY-MM-DDTHH:mm").valueOf(),
                moment(dateRange.max, "YYYY-MM-DDTHH:mm").valueOf()
            );
        }
    }, [dateRange]);

    const mapPlots = () => {
        if (!config || !config.charts || !data || !data._timestamps)
            return null;

        const timestamps = data._timestamps;
        let labels = timestamps.map((timestamp) => {
            let date = moment.unix(timestamp);
            return date.format("YYYY-MM-DDTHH:mm");
        });
        const plots = config.charts.map((chartConfig, index) => {
            const datasets = [];

            if (chartConfig.parameters && chartConfig.parameters.length) {
                for (let parameter of chartConfig.parameters) {
                    if (data[parameter.parName]) {
                        datasets.push(
                            generateDataset(
                                data[parameter.parName],
                                parameter.parName,
                                parameter.unit,
                                parameter.color,
                                timestamps,
                                parameter.side === "R" ? "y1" : "y"
                            )
                        );
                    }
                }
            }
            return (
                <StackPlotChart
                    config={chartConfig}
                    data={{ datasets, labels }}
                    options={defaultPlotOptions}
                    chartIndex={index}
                />
            );
        });
        plots.push(<StackPlotXAxis data={{ datasets: [], labels }} />);
        setCharts(plots);
    };

    const zoomAll = (xMin, xMax) => {
        zoomOthers(null, xMin, xMax);
    };

    const zoomOthers = (chartIndex, xMin, xMax) => {
        for (let index of chartRefs.keys()) {
            const chart = chartRefs.get(index).current;
            if (index === chartIndex) {
                continue;
            }
            if (chart) {
                chart.zoomScale("x", { min: xMin, max: xMax });
            } else {
                chartRefs.delete(index);
            }
        }
    };

    const generateDataset = (
        parData,
        parName,
        parUnit,
        color,
        timestamps,
        yAxisId = "y"
    ) => {
        let lineData = timestamps.map((timestamp) => ({
            x: timestamp * 1000,
            y: parData[timestamp] ? parData[timestamp][0] : null,
        }));
        let lineFlags = timestamps.map((timestamp) =>
            parData[timestamp] ? parData[timestamp][1] : null
        );
        const dataset = {
            type: "line",
            label: parName,
            borderColor: "#" + color,
            borderWidth: 2,
            backgroundColor: "transparent",
            fill: false,
            data: lineData,
            xAxisID: "x",
            yAxisID: yAxisId,
            tension: 0,
            min: getMin(lineData),
            max: getMax(lineData),

            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return `${parName}: ${tooltipItem.formattedValue} ${parUnit}`;
                    },
                    afterLabel: (tooltipItem) => {
                        return lineFlags[tooltipItem.dataIndex]
                            ? `${columns[1]}: ${
                                  lineFlags[tooltipItem.dataIndex]
                              }`
                            : null;
                    },
                },
            },
        };
        return dataset;
    };

    const getMin = (data) => {
        if (data.length === 0) return -Infinity;
        else if (data.length === 1) return data[0];
        else {
            let min = data[0];
            for (let i = 0; i < data.length; i++) {
                if (data[i] < min) min = data[i];
            }
            return min;
        }
    };

    const getMax = (data) => {
        if (data.length === 0) return -Infinity;
        else if (data.length === 1) return data[0];
        else {
            let max = data[0];
            for (let i = 0; i < data.length; i++) {
                if (data[i] > max) max = data[i];
            }
            return max;
        }
    };

    const getChartSpacing = () => {
        if (
            !config ||
            config.chartSpacing === undefined ||
            config.chartSpacing === null
        )
            return "20px";
        return config.chartSpacing + "px";
    };

    return (
        <StackPlotContext.Provider value={{ chartRefs, zoomOthers }}>
            <div className="stackplot-wrapper">
                <div className="stackplot-title" hidden={!config?.fullTitle}>
                    {config?.fullTitle || ""}
                </div>
                <div
                    className="stackplot-charts-container"
                    style={{ gap: getChartSpacing() }}
                >
                    {charts}
                </div>
                <div className="stackplot-footnote" hidden={!config?.footnote}>
                    {config?.footnote || ""}
                </div>
            </div>
        </StackPlotContext.Provider>
    );
}

function StackPlotChart({ chartIndex, config, data, options }) {
    const chartRef = useRef(null);

    const { chartRefs, zoomOthers } = useContext(StackPlotContext);

    useEffect(() => {
        if (chartRef.current?.canvas) {
            chartRefs.set(chartIndex, chartRef);
        } else {
            chartRefs.delete(chartIndex);
        }
    }, [chartRef.current]);

    const getLeftMin = () => {
        if (config.leftMin === undefined || config.leftMin === null) {
            const leftPars = config.parameters.filter(
                (parameter) => parameter.side === "L"
            );
            if (leftPars.length) {
                let min = leftPars[0].min;
                for (let i = 1; i < leftPars.length; i++) {
                    const parMin = data[leftPars[i].parName].min;
                    if (parMin < min) {
                        min = parMin;
                    }
                }
                return min;
            } else {
                return 0;
            }
        } else {
            return config.leftMin;
        }
    };

    const getLeftMax = () => {
        if (config.leftMax === undefined || config.leftMax === null) {
            const leftPars = config.parameters.filter(
                (parameter) => parameter.side === "L"
            );
            if (leftPars.length) {
                let max = leftPars[0].max;
                for (let i = 1; i < leftPars.length; i++) {
                    const parMax = data[leftPars[i].parName].max;
                    if (parMax < max) {
                        max = parMax;
                    }
                }
                return max;
            } else {
                return 0;
            }
        } else {
            return config.leftMax;
        }
    };

    const getRightMin = () => {
        if (config.rightMin === undefined || config.rightMin === null) {
            const rightPars = config.parameters.filter(
                (parameter) => parameter.side === "L"
            );
            if (rightPars.length) {
                let min = rightPars[0].min;
                for (let i = 1; i < rightPars.length; i++) {
                    const parMin = data[rightPars[i].parName].min;
                    if (parMin < min) {
                        min = parMin;
                    }
                }
                return min;
            } else {
                return 0;
            }
        } else {
            return config.rightMin;
        }
    };

    const getRightMax = () => {
        if (config.rightMax === undefined || config.rightMax === null) {
            const rightPars = config.parameters.filter(
                (parameter) => parameter.side === "L"
            );
            if (rightPars.length) {
                let max = rightPars[0].max;
                for (let i = 1; i < rightPars.length; i++) {
                    const parMax = data[rightPars[i].parName].max;
                    if (parMax < max) {
                        max = parMax;
                    }
                }
                return max;
            } else {
                return 0;
            }
        } else {
            return config.rightMax;
        }
    };

    const buildOptions = () => {
        let newOptions = JSON.parse(JSON.stringify(options));
        newOptions.scales.y.afterFit = (axis) => {
            axis.width = Y_AXIS_LABEL_WIDTH;
        };

        newOptions.scales.y.min = getLeftMin();
        newOptions.scales.y.max = getLeftMax();
        if (config.hasRightSide) {
            newOptions.scales.y1 = {
                type: "linear",
                position: "right",
                display: true,
                suggestedMin: 0,
                suggestedMax: 1,
                min: getRightMin(),
                max: getRightMax(),
                grid: {
                    drawOnChartArea: false,
                },
                afterFit: (axis) => {
                    axis.width = Y_AXIS_LABEL_WIDTH;
                },
            };
            newOptions.layout.padding.right = 0;
        }

        newOptions.plugins.zoom.zoom.onZoom = ({ chart }) => {
            const xMin = chart.scales.x.min;
            const xMax = chart.scales.x.max;

            zoomOthers(chartIndex, xMin, xMax);
        };

        newOptions.plugins.zoom.pan.onPan = ({ chart }) => {
            if (chart.options.plugins.zoom.pan.mode === "x") {
                const xMin = chart.scales.x.min;
                const xMax = chart.scales.x.max;

                zoomOthers(chartIndex, xMin, xMax);
            }
        };

        newOptions.plugins.zoom.zoom.onZoomStart = ({
            chart,
            event,
            point,
        }) => {
            if (event.ctrlKey) {
                event.preventDefault();
                const speed =
                    1 +
                    (event.deltaY >= 0
                        ? -chart.options.plugins.zoom.zoom.wheel.speed
                        : chart.options.plugins.zoom.zoom.wheel.speed);
                chart.zoom({
                    x: speed,
                    y: 1,
                    focalPoint: point,
                });
                return false;
            } else if (event.shiftKey) {
                event.preventDefault();
                const speed =
                    1 +
                    (event.deltaY >= 0
                        ? -chart.options.plugins.zoom.zoom.wheel.speed
                        : chart.options.plugins.zoom.zoom.wheel.speed);
                chart.zoom({
                    x: 1,
                    y: speed,
                    focalPoint: point,
                });
                return false;
            } else {
                return false;
            }
        };

        newOptions.plugins.zoom.pan.onPanStart = ({ chart, event }) => {
            if (event.srcEvent.shiftKey) {
                chart.options.plugins.zoom.pan.mode = "y";
                event.deltaX = 0;
            } else {
                chart.options.plugins.zoom.pan.mode = "x";
                event.deltaY = 0;
            }
        };

        newOptions.yDefaults = {
            left: {
                min:
                    config.leftMin !== null && config.leftMin !== undefined
                        ? getLeftMin()
                        : "original",
                max:
                    config.leftMax !== null && config.leftMax !== undefined
                        ? getLeftMax()
                        : "original",
            },
            right: {
                min:
                    config.rightMin !== null && config.rightMin !== undefined
                        ? getRightMin()
                        : "original",
                max:
                    config.rightMax !== null && config.rightMax !== undefined
                        ? getRightMax()
                        : "original",
            },
        };

        return newOptions;
    };

    const getLeftLegend = () => {
        if (!config?.parameters) return null;
        const leftParameters = config.parameters.filter(
            (parameter) => parameter.side === "L"
        );
        const legendItems = [];
        for (const par of leftParameters) {
            const legendItem = (
                <span
                    style={{ color: "#" + par.color }}
                >{`${par.parName} (${par.unit})`}</span>
            );
            legendItems.push(legendItem);
        }
        return legendItems;
    };

    const getRightLegend = () => {
        if (!config?.parameters) return null;
        const rightParameters = config.parameters.filter(
            (parameter) => parameter.side === "R"
        );
        const legendItems = [];
        for (const par of rightParameters) {
            const legendItem = (
                <span
                    style={{ color: "#" + par.color }}
                >{`${par.parName} (${par.unit})`}</span>
            );
            legendItems.push(legendItem);
        }
        return legendItems;
    };

    const dblClickPlugin = {
        id: "doubleClickZoomReset",
        beforeEvent: (chart, args) => {
            const event = args.event;
            if (event.type === "dblclick" && event.native.shiftKey) {
                console.log(chart.options.scales.y);
                const leftMin = chart.options.yDefaults.left.min;
                const leftMax = chart.options.yDefaults.left.max;
                chart.zoomScale("y", {
                    min: leftMin,
                    max: leftMax,
                });
                if (chart.options.scales.y1) {
                    const rightMin = chart.options.yDefaults.right.min;
                    const rightMax = chart.options.yDefaults.right.max;
                    chart.zoomScale("y1", {
                        min: rightMin,
                        max: rightMax,
                    });
                }
            } else {
                return;
            }
        },
    };

    const getPlugins = () => {
        return [dblClickPlugin];
    };

    return (
        <div className="stackplot-chart-wrapper">
            <div className="stackplot-chart-legend">
                <div className="stackplot-chart-legend-left">
                    {getLeftLegend()}
                </div>
                <div className="stackplot-chart-legend-right">
                    {getRightLegend()}
                </div>
            </div>
            <Chart
                type="line"
                data={data}
                options={buildOptions()}
                ref={chartRef}
                plugins={getPlugins()}
                style={{
                    width: "100%",
                    maxHeight:
                        (config.chartHeight ? config.chartHeight : 200) + "px",
                }}
            />
        </div>
    );
}

function StackPlotXAxis({ data }) {
    const chartRef = useRef(null);

    const { chartRefs } = useContext(StackPlotContext);

    useEffect(() => {
        if (chartRef.current) {
            chartRefs.set("x-axis", chartRef);
        }
    }, [chartRef.current]);

    return (
        <Chart
            className="stackplot-x-axis"
            data={data}
            options={xAxisOptions}
            ref={chartRef}
            style={{ maxHeight: "50px" }}
        />
    );
}

export default StackPlot;
